export const locations = {
  sydney: {
    city: "Sydney",
    address:
      "Suite 2 Level 1/20 Wentworth Street Parramatta, NSW 2150 Sydney, NSW 2000",
    phone: "1300 559 662",
    fax: "1300 559 699​​​​​​​",
    email: "info@whitecollarblue.com.au",
    newEmail: "nswvacancies@whitecollarblue.com.au",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.749035438599!2d151.00336525164386!3d-33.81878898057514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a3219aea5b15%3A0x195690d849d67bb8!2sSuite+2+Level+1%2F20+Wentworth+St%2C+Parramatta+NSW+2150%2C+Australia!5e0!3m2!1sen!2snz!4v1560899074160!5m2!1sen!2snz"
  },
  melbourne: {
    city: "Melbourne",
    address: "210/2 Infinity Drive, Truganina, VIC 3029",
    phone: "1300 559 175",
    fax: "1300 559 699​​​​​​​",
    email: "vic@whitecollarblue.com.au",
    newEmail: "vicvacancies@whitecollarblue.com.au",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16879.46524999035!2d144.73748563769558!3d-37.83668539053233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad689814ee7c77b%3A0x855e2faa82ccb09f!2sUnit%20210%2F2%20Infinity%20Dr%2C%20Truganina%20VIC%203029%2C%20Australia!5e0!3m2!1sen!2snz!4v1710823205221!5m2!1sen!2snz"
  },
  canberra: {
    city: "Canberra",
    address: "Fyshwick A.C.T Australia 2609​​",
    phone: "1300 559 662",
    fax: "1300 559 699​​​​​​​",
    email: "act@whitecollarblue.com.au",
    newEmail: "info@whitecollarblue.com.au",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12931.445586854416!2d149.1618203081919!3d-35.32218623008467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b164e7d14fe649b%3A0x500ea6ea76956e0!2sFyshwick%20ACT%202609%2C%20Australia!5e0!3m2!1sen!2snz!4v1566771079738!5m2!1sen!2snz"
  },
  brisbane: {
    city: "Brisbane",
    address: "Brisbane – Australia 4001",
    phone: "1300 407 070",
    fax: "1300 559 699​​​​​​​",
    email: "qld@whitecollarblue.com.au",
    newEmail: "qldvacancies@whitecollarblue.com.au",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d453481.30581140646!2d152.7123353500476!3d-27.38125200193089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91579aac93d233%3A0x402a35af3deaf40!2sBrisbane%20QLD%2C%20Australia!5e0!3m2!1sen!2snz!4v1566771422989!5m2!1sen!2snz"
  },
  southaustralia: {
    city: "Adelaide",
    address: "Marden Adelaide 5070​",
    phone: "1300 559 662",
    fax: "1300 559 699​​​​​​​",
    email: "sa@whitecollarblue.com.au",
    newEmail: "info@whitecollarblue.com.au",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6545.1132872402795!2d138.62936630105955!3d-34.89247792106684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0c97f934553bd%3A0x5033654628eb810!2sMarden%20SA%205070%2C%20Australia!5e0!3m2!1sen!2snz!4v1566771538432!5m2!1sen!2snz"
  },
  perth: {
    city: "Perth",
    address: "Perth Western Australia 6000",
    phone: "1300 559 662",
    fax: "1300 559 699​​​​​​​",
    email: "wa@whitecollarblue.com.au",
    newEmail: "info@whitecollarblue.com.au",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d432910.27669891104!2d115.68067612267787!3d-32.039172281024804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32966cdb47733d%3A0x304f0b535df55d0!2sPerth%20WA%2C%20Australia!5e0!3m2!1sen!2snz!4v1566771578859!5m2!1sen!2snz"
  }
};
